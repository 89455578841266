import { render, staticRenderFns } from "./leadsCanbanCard.vue?vue&type=template&id=6a99ddac&"
import script from "./leadsCanbanCard.vue?vue&type=script&lang=js&"
export * from "./leadsCanbanCard.vue?vue&type=script&lang=js&"
import style0 from "./leadsCanbanCard.vue?vue&type=style&index=0&id=6a99ddac&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLazy } from 'vuetify/lib/components/VLazy';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VChip,VIcon,VImg,VLazy,VProgressCircular})
