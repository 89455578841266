<template>
  <span class="card" @click="$emit('show-card', item.id)">
    <label class="label d-flex">
      <span class="lead-icon">
        <v-img v-if="item.source" :src="require(`@/assets/lead/${item.source.toLowerCase()}.png`)" height="20" width="20" contain />
        <span v-else>
          <v-icon style="font-size: 20px">mdi-help</v-icon>
          {{ item.source }}
        </span>
      </span>
      <div class="lead-id pl-1">#{{ item.id }}</div>
      <div v-if="item.data && item.data.offer_id" class="pl-2" style="">
        <v-icon style="font-size: 18px; vertical-align: baseline" @click.stop="openLink(item)">fas fa-external-link-alt</v-icon>
      </div>
    </label>

    <div class="kanban-action">
      <label>
        <strong><a-view-taskname :value="item.name" :values="item" /></strong>
      </label>
    </div>

    <label class="label">
      <div class="d-flex">
        <v-icon style="font-size: 14px; padding-right: 12px" :color="item.user_id ? 'green' : 'red'">far fa-user</v-icon>
        <a-view-table-select v-if="item.user_id" :value="item.user_id" :model="{ type: 'select', dir: 'user' }" />
      </div>
    </label>
    <small><a-view-table-datetime :value="item.updatedon || item.createdon" :model="{ format: 'shortTime' }" /></small>
    <v-lazy v-model="lazy" :options="{ threshold: 0.5 }" :key="item.id">
      <span v-if="lazy">
        <v-icon v-if="load_action == 3" style="font-size: 14px; padding-right: 12px" color="red">fas fa-exclamation-triangle</v-icon>
        <v-progress-circular v-if="load_action == 1" indeterminate color="green" />
        <v-icon style="font-size: 24px; padding-right: 12px" @click.native.stop="$emit('click-action', item)">mdi-checkbox-marked-circle-plus-outline </v-icon>
        <span class="mt-0" style="font-size: 12px; color: green" v-if="load_action == 2" v-for="action in actions" :key="`action-${action.id}`">{{
          getActionName(action.type)
        }}</span>
        <v-icon v-if="load_task == 3" style="font-size: 14px; padding-right: 12px" color="red">fas fa-exclamation-triangle</v-icon>

        <v-progress-circular v-if="load_task == 1" indeterminate color="green" />
        <br />
        <v-chip class="mt-1" v-if="load_task == 2" v-for="task in tasks" :key="task.id" small @click.native.stop="$emit('click-task', task)">{{
          getTaskName(task.type)
        }}</v-chip>
      </span>
    </v-lazy>
  </span>
</template>

<script>
import { getAccess, autoHeightBlock, genModel } from "@/components/mixings";
export default {
  mixins: [getAccess, autoHeightBlock, genModel],
  name: "LeadsCardKanban",
  props: {
    item: Object,
  },
  components: {},
  data() {
    return {
      lazy: false,
      m: this.$store.getters["config/get"].models.leads,
      load_action: 0,
      actions_: [],
      load_task: 0,
      tasks_: [],
      type: [...this.$store.state.config.data.config.dictionary.lead_actions],
    };
  },

  computed: {
    tasks() {
      let res = [];
      if (!this.load_task) {
        this.getTasks();
      }
      if (this.load_task == 2) res = this.tasks_;
      return res;
    },
    actions() {
      let res = [];
      if (!this.load_action) {
        this.getLastAction();
      }
      if (this.load_action == 2) res = this.actions_;
      return res;
    },
  },
  watch: {
    "item.updatedon"() {
      this.load_task = 0;
      this.load_action = 0;
    },
  },
  mounted() {},
  methods: {
    openLink(item) {
      const id = item?.data?.offer_id;
      if (!id) return;
      let link;
      if (item.source == "cian") link = "https://www.cian.ru/sale/flat/" + id;
      if (item.source == "avito") link = "https://www.avito.ru/" + id;
      if (!link) return;
      window.open(link, "_blank");
    },
    getTaskName(v) {
      return (this.type.find((t) => t.value == v) || {})?.text || v;
    },
    getActionName(v) {
      return (this.type.find((t) => t.value == v) || {})?.action_text || v;
    },
    async getTasks() {
      this.load_task = 1;
      try {
        const params = { filters: { lead_id: this.item.id, status: 1 } };
        let d = await this.$axios.get(this.m.tasks.api, { params });
        this.tasks_ = [...d.data.data];
        this.load_task = 2;
      } catch (error) {
        this.load_task = 3;
        // this.$root.$emit("show-info", { text: "Ошибка загрузки данных" });
      }
    },
    async getLastAction() {
      this.load_action = 1;
      try {
        const params = { sort: { key: "id", order: "DESC" }, filters: { lead_id: this.item.id }, pager: { limit: 1 } };
        let d = await this.$axios.get(this.m.actions.api, { params });
        this.actions_ = [...d.data.data];
        this.load_action = 2;
      } catch (error) {
        this.load_action = 3;
        // this.$root.$emit("show-info", { text: "Ошибка загрузки данных" });
      }
    },
  },
};
</script>

<style lang="scss">
label.label {
  align-items: center;
  span.lead-icon1 {
    position: absolute;
  }
  div.lead-id1 {
    position: relative;
    left: 35px;
  }
}
</style>
